<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {
  }
}
</script>

<style>
#app{
  background: #f5f5f6;
}
/* ::-webkit-scrollbar{display: none;} */
</style>
